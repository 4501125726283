<template>
	<div v-if="!isAuthenticated">
		<p
			class="h6 px-3 pb-3"
			v-if="$siteconf.cooptation"
		>
			{{ $t('mail-phone.coop') }}
		</p>
		<p
			v-else
			class="h6 px-3 pb-3"
		>
			{{ $siteconf.contact_choice == 'both' ? $t('mail-phone.deux') :
				$siteconf.contact_choice == 'email' ? $t('mail-phone.mail-text') : $siteconf.contact_choice == 'phone' ?
					$t('mail-phone.mail-text') : '' }}
		</p>
		<form
			@submit="accept"
			class="px-3 connect-form d-flex flex-column spaced-2"
		>
			<MatInput
				v-model="contactInfo.email"
				:required="$siteconf.contact_choice == 'both' || $siteconf.contact_choice == 'email'"
				:label="$t('mail-phone.mail')"
				type="email"
				name="email"
			/>
			<MatInput
				v-model="contactInfo.phone"
				:required="$siteconf.contact_choice == 'both' || $siteconf.contact_choice == 'phone'"
				:label="$t('mail-phone.phone')"
				type="tel"
				name="phone"
			/>
			<span
				class="d-flex flex-vertical-center "
				v-if="!this.$siteconf.cooptation"
			>
				<MatCheckbox
					v-model="contactInfo.accept"
					id="acceptTerms"
				/>
				<label for="acceptTerms">
					{{ $t('accept') }} <a
						class="body1 text-center under"
						:href="$siteconf.policy_link || '/privacy'"
						target="_blank"
					>{{ $t('cond') }}</a>
				</label>
			</span>
			<div class="text-center mt-2 mb-4">
				<MatButton
					outlined
					v-if="$siteconf?.cooptation"
					:label="$t('return')"
					@click="onPrevClick"
					class="me-4"
				/>
				<MatButton
					v-if="isAuthenticated || !$siteconf.accounts "
					:disabled="mail || (!contactInfo.accept && !this.$siteconf.cooptation)"
					raised
					type="submit"
				>
					{{ $t('suiv') }}
				</MatButton>

				<span
					v-if="!isAuthenticated && $siteconf.accounts"
					class="d-flex flex-column "
				>
					<MatButton
						class="mb-2 m-auto w-50"
						raised
						@click="action($event, 'sansCompte')"
						:disabled="mail || (!contactInfo.accept && !this.$siteconf.cooptation)"
					>
						{{ $t('candiSansCompte') }}
					</MatButton>
					<span class="d-flex m-auto">
						<MatButton
							@click="action($event, 'signin')"
							raised
							class="me-3"
						>
							{{ $t('connexion') }}
						</MatButton>
						<MatButton
							@click="action($event, 'signup')"
							raised
							class=""
						>
							{{ $t('creer') }}
						</MatButton>
					</span>


				</span>
			</div>
		</form>
	</div>
	<div v-else>
		<h6
			v-if="userData"
			class="d-flex flex-center"
		>
			{{ $t('connectedTanque') }} {{ userData }}
		</h6>
		<div class="d-flex justify-content-center  buttons py-4 ">
			<div
				class="px-2 py-4 m-2 rounded-shadow"
				@click="action($event, 'modif')"
			>
				<span class="material-icons-outlined flex-center text-primary h4 border border-2 rounded-circle">
					edit
				</span>
				<p class="h6 text-center txt-secondary flex-center">
					{{ $t('modCand') }}
				</p>
			</div>
			<div
				class="px-2 py-4 m-2 rounded-shadow"
				@click="action($event, 'sendCandidature')"
			>
				<span class="material-icons-outlined flex-center text-primary h4 border border-2 rounded-circle">
					send
				</span>
				<p class=" h6 text-center txt-secondary">
					{{ $t('envCand') }}
				</p>
			</div>
		</div>
	</div>
</template>
<script>
import { ROUTES_NAME } from "@/router";
const verifEmail = /^[A-Za-z0-9._+'-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
const verifphone = /^[+\d]\d{5,}$/
export default {
	emits: ['updateCandidData'],
	data() {
		return {
			ROUTES_NAME,
			contactInfo: {},
			val: false,
			user: null,
			userData: null
		}
	},
	computed: {
		mail() {
			const m = this.contactInfo.email ? this.contactInfo.email.toLowerCase().trim() : null;
			const p = this.contactInfo.phone ? this.contactInfo.phone.replace(/[^+\d]/g, '') : null;
			if (m && !verifEmail.exec(m)) return true;
			if (p && !verifphone.exec(p)) return true;
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			if (this.$siteconf.contact_choice == "both" && (m && verifEmail.exec(m)) &&
				(p && verifphone.exec(p))) return false
			else if (this.$siteconf.contact_choice == "email" && (m && verifEmail.exec(m))) return false
			else if (this.$siteconf.contact_choice == "phone" && (p && verifphone.exec(p))) return false
			else return true
		},
		isAuthenticated() {
			return this.$store.state.token;
		}
	},
	props: {
		onNextClick: {
			type: Function,
			default: () => () => alert("next"),
		},
		data: {
			type: Object,
			default: () => null
		},
		onPrevClick: {
			type: Function,
			default: () => () => alert("prev")
		},
	},
	watch: {
		data() {
			this.contactInfo = this.data
		},
		modelValue: {
			handler() {
				this.value = this.modelValue;
			},
			immediate: true,
		},
	},
	async mounted() {
		if (this.isAuthenticated) {
			try {
				this.user = await this.$service.user.getUser();
				const  personal  = this.user.personal || {};
				this.userData = personal ? `${personal.firstname || ''} ${personal.lastname || ''}`.trim() : this.$store.state.email || null;

			} catch (error) {
				this.userData = this.$store.state.email ? this.$store.state.email : null
			}
		}
		if (this.$store.state.token) return this.onNextClick("connected")
		this.contactInfo = this.data;

		document.body.scrollTop = 0;
	},
	methods: {
		accept(e) {
			e.preventDefault()
			const { email, phone, accept } = this.contactInfo;
			this.$userData({ email, phone, accept });
			if (typeof (this.onNextClick) == "function")
				return this.onNextClick()
		},
		action(e, type) {
			e.preventDefault()
			if (type == 'sansCompte') {
				const { email, phone, accept } = this.contactInfo;
				this.$userData({ email, phone, accept });
				return this.onNextClick("sansCompte")
			}
			if (type == 'signin') {
				return this.onNextClick("signin")
			}
			if (type == 'signup') {
				return this.onNextClick("signup")
			}
			if (type == 'modif') {
				return this.onNextClick("modif")
			}
			if (type == 'sendCandidature') {
				return this.onNextClick("sendCandidature")
			}
		}

	},
}
</script>
<style lang="scss" scoped>
.buttons>* {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 184px;
	height: 270px;
	cursor: pointer;

	&:hover {
		//margin-top: -3px !important;
		transform: scale(1.05);
		transition-duration: 0.4s;
		border-style: solid;
		border-color: var(--primary-color) !important;
		border-width: 1px;

		.h4 {
			border-color: var(--primary-color) !important;
			border-width: 1px !important;
		}

		h6 {
			color: var(--primary-color) !important;
		}
	}
}

.h4 {
	height: 70px;
	width: 70px;
	margin-top: 50px;

}
</style>