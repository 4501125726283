mbits = function(n,b) { return n & ((1 << b)-1) };
sbits = function(n,b,s) { return mbits(n,b) << s };
bits = function(n,b,s) { return typeof(s) === 'undefined' ? mbits(n,b) : sbits(n,b,s) };

var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_';
var has_buf = typeof Buffer == 'function';

hex_char = hex_4bits = function(i) { return chars[mbits(i, 4)] };
hex_2chars = hex_byte = function(i) { return hex_4bits(i >>> 4) + hex_4bits(i) };
hex_4chars = hex_2bytes = hex_short = function(i) { return hex_byte(i >>> 8) + hex_byte(i) };
hex_6chars = hex_3bytes = function(i) { return hex_1byte(i >>> 16) + hex_2bytes(i) };
hex_8chars = hex_4bytes = hex_word = function(i) { return hex_2bytes(i >>> 16) + hex_2bytes(i) };

b64_char = b64_6bits = function(i) { return chars[mbits(i, 6)] };
b64_2chars_ = function(i) { return b64_6bits(i >>> 6) + b64_6bits(i) };
b64_3chars_ = function(i) { return b64_6bits(i >>> 12) + b64_6bits(i >>> 6) + b64_6bits(i) };
b64_4chars = b64_3bytes = function(i) {
	return b64_6bits(i >>> 18) + b64_6bits(i >>> 12) + b64_6bits(i >>> 6) + b64_6bits(i)
};
b64_5chars = b64_30bits = function(i) {
	return b64_6bits(i >>> 24) + b64_6bits(i >>> 18) + b64_6bits(i >>> 12) + b64_6bits(i >>> 6) + b64_6bits(i)
};
b64_12bytes = b64_3words = function(a,b,c) {
	return  b64_30bits(a >>> 2) + b64_6bits((a << 4) | (b >>> 28)) + b64_3bytes(b >>> 4) + b64_6bits((b << 2) | (c >>> 30)) + b64_30bits(c)
};
b64_byte_ = function(i) { return b64_6bits(i >>> 2) + chars[mbits(i,2) << 4] };
b64_2bytes_ = b64_short_ = function(i) { return b64_6bits(i >>> 10) + b64_6bits(i >>> 4) + chars[mbits(i,4) << 2] };
b64_4bytes_ = b64_word_ = function(i) { return b64_3bytes(i >>> 8) + b64_byte_(i) };
b64_8bytes_ = b64_2words_ = function(a,b) {
	return b64_30bits(a >>> 2) + b64_char((a << 4) | (b >>> 28)) + b64_3bytes(b >>> 4) + chars[mbits(b,4) << 2]
};

toBin = function(message) {
	if(typeof message === 'string') {
		if(has_buf) return Buffer.from(message);
		if(typeof TextEncoder !== 'undefined') return (new TextEncoder()).encode(message);
	} else if(typeof message === 'object') {
		if(message.constructor === ArrayBuffer) return new Uint8Array(message);
		if(message.constructor === Uint8Array) return message;
		if(has_buf && message.constructor === Buffer) return message;
	}
	throw new Error('unsupported message type to hash');
}

bin_to_hex = function(bin) {
	var out = '';
	bin = toBin(bin);
	for(var i = 0; i < bin.length; ++i)
		out += hex_byte(bin[i]);
	return out;
}
bin_to_b64 = function(bin) {
	var out = '';
	bin = toBin(bin);
	var blocks = Math.floor(bin.length / 3), b = 0;
	for(var block = 0; block < blocks; ++block) {
		out += b64_3bytes((bin[b] << 16) | (bin[b+1] << 8) | bin[b+2]);
		b += 3;
	}
	switch(bin.length % 3) {
		case 0: return out;
		case 1: return out + b64_byte_(bin[b]);
		case 2: return out + b64_2bytes_((bin[b] << 8) | bin[b+1]);
	}
}

hex_ch = function(c) {
	if(typeof(c) === 'string') c = c.charCodeAt(0);
	if(c >= 48 && c < 58) return c - 48;
	else if(c >= 97 && c < 103) return c - 87;
	else throw new Error('char ' + c + ' is not hex');
}
hex_to_bin = function(str) {
	var blocks = Math.floor(str.length / 2);
	var extra = str.length & 1;
	var nb = blocks + extra;
	var result = has_buf ? Buffer.alloc(nb) : new ArrayBuffer(nb);
	var bytes = has_buf ? result : new Uint8Array(result);
	for(var b = 0; b < blocks; ++b) bytes[b] = (hex_ch(str.charCodeAt(b*2)) << 4) | hex_ch(str.charCodeAt(b*2+1));
	if(extra) bytes[b] = hex_ch(str.charCodeAt(str.length-1));
	return result;
}

b64_ch = function(c) {
	var r = chars.indexOf(c);
	if(r < 0) throw new Error('char ' + c + ' is not b64');
	return r;
}
b64_to_bin = function(str) {
	var blocks = Math.floor(str.length / 4);
	var extraChars = str.length % 4, extraBytes;
	switch(extraChars) {
		case 0: extraBytes = 0; break;
		case 1: throw new Error('bad base64 length');
		case 2: extraBytes = 1; break;
		case 3: extraBytes = 2; break;
	}
	var nb = blocks * 3 + extraBytes;
	var result = has_buf ? Buffer.alloc(nb) : new ArrayBuffer(nb);
	var bytes = has_buf ? result : new Uint8Array(result);
	for(var block = 0; block < blocks; ++block) {
		var a = b64_ch(str[block*4]), b = b64_ch(str[block*4+1]), c = b64_ch(str[block*4+2]), d = b64_ch(str[block*4+3]);
		bytes[block*3] = (a << 2) | (b >>> 4);
		bytes[block*3+1] = (mbits(b, 4) << 4) | (c >>> 2);
		bytes[block*3+2] = (mbits(c, 2) << 6) | d;
	}
	if(extraChars) {
		var a = b64_ch(str[block*4]), b = b64_ch(str[block*4+1]), c;
		bytes[block*3] = (a << 2) | (b >>> 4);
		if(extraChars == 3) {
			c = b64_ch(str[block*4+2]);
			bytes[block*3+1] = (mbits(b, 4) << 4) | (c >>> 2);
		}
	}
	return result;
}

if(typeof Meteor === 'undefined' && typeof module === 'object') {
	module.exports.b64_3words = b64_3words;
	module.exports.b64_2words_ = b64_2words_;
	module.exports.b64_word_ = b64_word_;
	module.exports.b64_char = b64_char;
	module.exports.hex_word = hex_word;
	module.exports.toBin = toBin;
	module.exports.hex_to_bin = hex_to_bin;
	module.exports.b64_to_bin = b64_to_bin;
	module.exports.bin_to_hex = bin_to_hex;
	module.exports.bin_to_b64 = bin_to_b64;
}
