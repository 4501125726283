<!-- eslint-disable no-control-regex -->
<template lang="">
	<ParentForm
		class=""
		@drop="ondrop"
		@dragover="dragOver"
		@dragleave="dragLeave"
		@keydown="handleKeydown"
	>
		<p
			v-if="error"
			class="text-center text-danger h6"
		>
			{{ $t('fileError.'+error) }}
		</p>
		<input
			class="d-none"
			type="file"
			ref="resumeInput"
			accept=".pdf,.doc,.docx,.odt,.rtf,.png,.jpg,.jpeg"
			@change="manualSelect"
		>
		<div class="text-center spaced-3 p-2">
			<div class="spaced-2 w-fit m-auto drop-zone">
				<div
					class="file-section p-4 spaced-1 rounded"
				>
					<div
						class="subtitle1"
						v-if="isDragging"
					>
						{{ file && state==2 ? $t('docTele') : $t('drop') }}
					</div>
					<div
						class="subtitle1"
						v-else
					>
						{{ file && state==2 ? $t('docTele') : $t('uploadFile') }}
					</div>
					<div class="icon-section">
						<Transition name="slide-down">
							<div
								v-if="state == 0"
								class="position-relative w-100 h-100"
							>
								<circle-progress
									:progress="progress + '%'"
									class="upload-progress"
								/>
								<span
									class="material-icons-outlined text-primary flex-center border download-icon pointer"
								>
									download
								</span>
								<div
									class="w-100 h-100 pointer position-absolute"
									style="z-index: 100"
									@click=" $refs.resumeInput.click()"
								/>
							</div>

							<span
								v-else-if="state == 1"
								class="material-icons-outlined text-primary flex-center border download-icon"
							>
								done
							</span>
							<span
								v-else
								class="material-icons-outlined text-primary flex-center border download-icon"
							>
								description
							</span>
						</Transition>
					</div>
					<div
						v-if="state == 2"
						class="subtitle1 txt-secondary"
						:class="{'txt-primary': file.name}"
					>
						{{ file.name || $t('format') }}
					</div>
				</div>
				<div class="position-relative">
					<div
						v-if="state == 2"
						class="spaced-3"
					>
						<div class="flex-vertical-center justify-content-between">
							<div class="caption txt-secondary">
								<!-- {{ $t('miseAjour') }} -->
							</div>
							<MatButton
								outlined
								@click="$refs.resumeInput.click()"
								:title="$t('modifierFile')"
							>
								{{ $t('mj') }}
							</MatButton>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ParentForm>
</template>
<script>
import MatButton from "@/components/material/mat-button";
import { sha256_b64 } from "@/utils/sha256"

const supportedMIME = {
	'application/msword': 'doc',
	'application/pdf': 'pdf',
	'application/rtf': 'rtf',
	'application/vnd.oasis.opendocument.text': 'odt',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
	'image/jpeg': ['jpg', 'jpeg'],
	'image/png': ['png']
}
function extension(name) {
	const dot = name.lastIndexOf('.');
	if (dot <= 0) return; // forbid unix hidden files like '.htaccess'
	return name.substring(dot + 1).toLowerCase();
}
export default {
	components: {
		MatButton,
	},
	emits: ["update:modelValue"],
	props: {
		// data: {
		// 	type: Array,
		// 	default: () => []
		// },
		modelValue: {
			type: Array,
			default: null
		},
		offerId: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			file: null,
			state: 0,
			loading: false,
			progress: 0,
			isDragging: false,
			error: '',
			sizeLimit: 5e6,
			files: []
		}
	},
	watch: {
		modelValue: {
			immediate: true
		}
	},
	methods: {
		fileChecker(file) {
			const mimeExt = file.type && supportedMIME[file.type];
			const nameExt = extension(file.name);
			if (!mimeExt || !nameExt || (typeof mimeExt == 'string' && mimeExt != nameExt) || (!mimeExt.includes(nameExt)))
				this.error = 'type';
			else if (file.size > this.sizeLimit)
				this.error = 'size';
			else {
				this.error = '';
				this.fileSelected(file);
			}
		},
		manualSelect() {
			this.error = '';
			this.file = null;
			const file = this.$refs.resumeInput.files[0];
			this.fileChecker(file);
		},
		async fileSelected(file) {
			this.state = 0
			this.loading = true
			this.progress = 0
			this.file = file
			await this.uploadFile(file);
			this.loading = false;
		},
		async calculateHash(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.onload = async () => { resolve(sha256_b64(reader.result)) }
				reader.onerror = function (e) { reject(e) };
				reader.readAsArrayBuffer(file)
			})
		},
		async uploadFile(file) {
			try {
				const hash = await this.calculateHash(file)
				// this.$userData(null, 'file', file);
				this.state = 2
				this.$refs.resumeInput.value = '';
				this.$emit('update:modelValue', {hash, file});
			} catch (error) {
				// TODO meilleur messages d'erreurs
				this.state = 0
				console.log(error.message);
				this.error = 'upload'
				this.progress = 0
				console.log(error);
			}
		},
		onUploadProgress(e) {
			this.progress = Math.round((100 * e.loaded) / e.total);
		},
		dragOver(e) {
			e.preventDefault();
			this.isDragging = true;
		},
		dragLeave() {
			this.isDragging = false;
		},
		ondrop(e) {
			e.preventDefault();
			this.error = '';
			this.file = null;
			this.$refs.resumeInput.files = e.dataTransfer.files;
			this.isDragging = false;
			const file = this.$refs.resumeInput.files[0];
			this.fileChecker(file);
		},
		fileExists(fileName) {
			return this.files.some(file => file.name === fileName);
		},
		async removeAttachment(file, index) {
			try {
				console.log(file, { index })
				if (this.offerId) await this.$service.offer.removeAttachment(this.offerId, file.token);
				this.files.splice(index, 1)
			} catch (error) {
				this.error = 'upload'
				console.log(error)
			}
		}
	},

	async mounted() {
		if (this.files==[] && this.$refs.resumeInput) this.$refs.resumeInput.value = '';

	},

}
</script>
<style lang="scss" scoped>
.file-section {
	display: flex;
	flex-direction: column;
	border: 1px dashed var(--primary-color);
	width: fit-content;
	margin: auto;
	width: 320px;
}

.icon-section {
	height: 50px;
	width: 50px;
	margin: auto;
	position: relative;
}

.upload-progress {
	position: absolute;
	width: 100%;
	height: 100%;
	--color: var(--primary-75);
	--thickness: 10px;
}

.download-icon {
	font-size: 30px;
	height: 50px;
	width: 50px;
	border-radius: 50%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;

}

.subtitle1 {
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
</style>
