export default class Candidature {
	constructor(http) { this.http = http }
	params() {
		const p = {
			source: window.utm?.source,
			medium: window.utm?.medium,
			campaign: window.utm?.campaign,
		};
		const q = Object.entries(p)
			.filter(kv => kv[1] !== undefined)
			.map(kv => `${kv[0]}=${encodeURIComponent(kv[1])}`)
		return q.length ? '?' + q.join('&') : '';
	}
	submit(shortId, formData, onUploadProgress) {
		return this.http.post(`offer/${shortId}/fields${this.params()}`, formData,
			{ headers: { 'Content-Type': 'multipart/form-data' }, onUploadProgress }
		);
	}
	submitManual(shortId, candidate) {
		return this.http.post(`offer/${shortId}/fields${this.params()}`, candidate);
	}
	getForm(shortId){
		return this.http.get(`offer/${shortId}/fields`);
	}
	getFormByToken(token){
		return this.http.get(`questionnaire/${token}`);
	}
	saveFormByToken(token,formData){
		//console.log(id);
		// return this.http.post(`questionnaire/${token}`,{"answers":data,"id":id});
		return this.http.post(`questionnaire/${token}`, formData,
		{ headers: { 'Content-Type': 'multipart/form-data' }}
	);
	}
}