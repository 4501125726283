<!-- eslint-disable no-control-regex -->
<template lang="">
	<ParentForm
		:title="!$siteconf.cooptation ? $t('teleCv'): $t('teleCvCoop')"
		class=""
		@drop="ondrop"
		@dragover="dragOver"
		@dragleave="dragLeave"
		@keydown="handleKeydown"
		tabindex="0"
	>
		<p
			v-if="error"
			class="text-center text-danger h6"
		>
			{{ $t('fileError.'+error) }}
		</p>
		<input
			class="d-none"
			type="file"
			ref="resumeInput"
			accept=".pdf,.doc,.docx,.odt,.rtf,.png,.jpg,.jpeg"
			@change="manualSelect"
		>
		<div class="text-center spaced-3 p-2">
			<div class="spaced-2 w-fit m-auto drop-zone">
				<div
					class="file-section p-4 spaced-1 rounded"
				>
					<div
						class="subtitle1"
						v-if="isDragging"
					>
						{{ file && state==2 ? $t('docTele') : $t('drop') }}
					</div>
					<div
						class="subtitle1"
						v-else
					>
						{{ file && state==2 ? $t('docTele') : $t('upload') }}
					</div>
					<div class="icon-section">
						<Transition name="slide-down">
							<div
								v-if="state == 0"
								class="position-relative w-100 h-100"
							>
								<circle-progress
									:progress="progress + '%'"
									class="upload-progress"
								/>
								<span
									class="material-icons-outlined text-primary flex-center border download-icon pointer"
								>
									download
								</span>
								<div
									class="w-100 h-100 pointer position-absolute"
									style="z-index: 100"
									@click=" $refs.resumeInput.click()"
								/>
							</div>

							<span
								v-else-if="state == 1"
								class="material-icons-outlined text-primary flex-center border download-icon"
							>
								done
							</span>
							<span
								v-else
								class="material-icons-outlined text-primary flex-center border download-icon"
							>
								description
							</span>
						</Transition>
					</div>
					<div
						v-if="state == 2"
						class="subtitle1 txt-secondary"
						:class="{'txt-primary': file.name}"
					>
						{{ file.name || $t('format') }}
					</div>
				</div>
				<div class="position-relative">
					<div
						v-if="state == 2 && !$siteconf.cooptation"
						class="spaced-3"
					>
						<div class="flex-vertical-center justify-content-between">
							<div class="caption txt-secondary">
								{{ $t('miseAjour') }}
							</div>
							<MatButton
								outlined
								@click="$refs.resumeInput.click()"
								:title="$t('modifierFile')"
							>
								{{ $t('mj') }}
							</MatButton>
						</div>
						<div class="text-center mt-4">
							<MatButton
								outlined
								class="me-4"
								:label="$t('return')"
								@click="onPrevClick"
							/>
							<MatButton
								raised
								:label="labelBtn"
								@click="onNextClick"
								:disabled="loading"
							/>
						</div>
					</div>

					<div
						v-if="$siteconf.cooptation"
						class="spaced-3"
					>
						<div
							class="flex-vertical-center justify-content-between"
							v-if="state == 2"
						>
							<div class="caption txt-secondary">
								{{ $t('miseAjour') }}
							</div>
							<MatButton
								outlined
								@click="$refs.resumeInput.click()"
								:title="$t('modifierFile')"
							>
								{{ $t('mj') }}
							</MatButton>
						</div>
						<div class="text-center mt-4">
							<MatButton
								outlined
								class="me-4"
								:label="$t('return')"
								@click="onPrevClick"
							/>
							<MatButton
								raised
								:label="labelBtn"
								@click="nextStep()"
								:disabled="loading || (!file && this.$siteconf?.cooptation_cv)"
							/>
						</div>
					</div>
					<!--
						<div
						v-else
						class="subtitle1 spaced-3 d-none"
					>
						Ou à partir du cloud
						<div class="flex-horizontal-center h-spaced-2">
							<MatFab outlined>
								<i
									class="fa-solid fa-cloud text-primary"
									slot="icon"
								/>
							</MatFab>
							<MatFab outlined>
								<i
									class="fa-brands fa-google-drive text-primary"
									slot="icon"
								/>
							</MatFab>
							<MatFab outlined>
								<i
									class="fa-brands fa-dropbox text-primary"
									slot="icon"
								/>
							</MatFab>
						</div>
					</div>
					-->
				</div>
			</div>
		</div>
	</ParentForm>
</template>
<script>
import '@/components/circle-progress'
const supportedMIME = {
	'application/msword': 'doc',
	'application/pdf': 'pdf',
	'application/rtf': 'rtf',
	'application/vnd.oasis.opendocument.text': 'odt',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
	'image/jpeg': ['jpg', 'jpeg'],
	'image/png': ['png']
}
function extension(name) {
	const dot = name.lastIndexOf('.');
	if (dot <= 0) return; // forbid unix hidden files like '.htaccess'
	return name.substring(dot + 1).toLowerCase();
}
export default {
	data() {
		return {
			file: null,
			state: 0,
			loading: false,
			progress: 0,
			isDragging: false,
			error: '',
			sizeLimit: 5e6,
			shortid: null,
			id: "",
			clicked: false,
			suivLabel: ""
		}
	},
	props: {
		data: {
			type: Object,
			default: () => { }
		},
		candidatId: {
			type: String,
			default: ''
		},
		onNextClick: {
			type: Function,
			default: () => () => alert("next")
		},
		onPrevClick: {
			type: Function,
			default: () => () => alert("prev")
		}
	},
	computed: {
		labelBtn() {
			if (this.loading) return this.$t('waiting');
			else return this.$siteconf.cooptation ? this.$t('soumettreCoop') : this.$t('suiv')
		}
	},
	methods: {
		handleKeydown(event) {
			if (event.key === 'Enter') {
				event.preventDefault();
				event.target.blur();
				const { cooptation, cooptation_cv } = this.$siteconf;
				const hasFile = this.file;

				if (cooptation && !cooptation_cv && !this.loading) {
					this.nextStep();
				} else if (!cooptation && hasFile && !this.loading) {
					this.onNextClick();
				} else {
					return;
				}

			}
		},
		fileChecker(file) {
			const mimeExt = file.type && supportedMIME[file.type];
			const nameExt = extension(file.name);
			if (!mimeExt || !nameExt || (typeof mimeExt == 'string' && mimeExt != nameExt) || (!mimeExt.includes(nameExt)))
				this.error = 'type';
			else if (file.size > this.sizeLimit)
				this.error = 'size';
			else {
				this.error = '';
				this.fileSelected(file);
			}
		},
		manualSelect() {
			this.error = '';
			this.file = null;
			const file = this.$refs.resumeInput.files[0];
			this.fileChecker(file);
		},
		async fileSelected(file) {
			this.state = 0
			this.loading = true
			this.progress = 0
			this.file = file
			await this.uploadFile(file);
			this.loading = false;
			// this.onNextClick()
		},
		async uploadFile(file) {
			try {
				const formData = new FormData();
				formData.append('id', this.id);
				formData.append('file', this.file);
				await this.$service.candidature.submit(this.shortid, formData, this.onUploadProgress);
				this.$userData(null, 'file', file);
				this.state = 2
			} catch (error) {
				// TODO meilleur gestion d'erreur
				console.log(error.message);
				this.error = 'upload'
				this.progress = 0
			}
		},
		onUploadProgress(e) {
			this.progress = Math.round((100 * e.loaded) / e.total);
		},
		dragOver(e) {
			e.preventDefault();
			this.isDragging = true;
		},
		dragLeave() {
			this.isDragging = false;
		},
		ondrop(e) {
			e.preventDefault();
			this.error = '';
			this.file = null;
			this.$refs.resumeInput.files = e.dataTransfer.files;
			this.isDragging = false;
			const file = this.$refs.resumeInput.files[0];
			this.fileChecker(file);

		},
		async nextStep() {
			this.loading = true
			await this.onNextClick()
		}
	},
	watch: {
		data() { this.file = this.data },
		candidatId: {
			handler() {
				this.id = this.candidatId;
			},
			immediate: true
		},
	},
	async mounted() {
		this.$toMove.shortId ? this.shortid = this.$toMove.shortId : this.shortid = this.$route.params.offerId
		if (this.$route.params.offerId == 0) this.shortid = 0
		this.file = this.data || null;
		const saved = await this.$userData(null, 'file');
		if (saved)
			this.fileChecker(saved);
		else {
			this.file = null;
			this.state = 0;
		}
	},

}
</script>
<style lang="scss" scoped>
.file-section {
	display: flex;
	flex-direction: column;
	border: 1px dashed var(--primary-color);
	width: fit-content;
	margin: auto;
	min-width: 350px;
}

.icon-section {

	height: 70px;
	width: 70px;
	margin: auto;
	position: relative;
}

.upload-progress {
	position: absolute;
	width: 100%;
	height: 100%;
	--color: var(--primary-75);
	--thickness: 10px;
}

.download-icon {
	font-size: 30px;
	height: 70px;
	width: 70px;
	border-radius: 50%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;

}
</style>
